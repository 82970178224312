










































































































































































































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

.main-content {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

.tab {
    text-align: right;
}

.content {
    flex: 1;
    height: 1%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.form-content {
    flex: 1;
    height: 1%;
    margin-top: 20px;
}

.el-input {
    width: 500px;
}

.el-textarea {
    width: 500px;
}

.goods-main {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .bottom-goods {
        margin-top: 20px;
    }
}

.show-goods {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .goods-item {
        width: 120px;
        position: relative;

        &:hover {
            i {
                opacity: 1;
            }
        }

        i {
            position: absolute;
            right: -12px;
            top: -12px;
            font-size: 24px;
            cursor: pointer;
            opacity: 0;
            color: red;
        }
    }
}

.goods-item {
    border: 1px solid #eee;
    width: calc(20% - 20px);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .item-cover {
        width: 100px;
        height: 100px;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .item-shadow {
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 24px;
            }
        }
    }

    &:nth-of-type(5n) {
        margin-right: 0;
    }

    .item-text {
        margin: 10px 0;
    }

    &:hover {
        cursor: pointer;
    }
}

::v-deep .custom-dialog {
    min-width: 500px;
}

.other-item {
    position: relative;

    &::after {
        position: absolute;
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
        left: 100px;
        top: 12px;
    }
}

.limit-text {
    color: #606266;
    font-size: 14px;
    margin-left: 20px;
}

.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}

